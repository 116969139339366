import React, { useState, useEffect } from 'react';
import './Gallery.css';

const Gallery = ({ selectedArtist }) => {
    const [artists, setArtists] = useState([
        { artist_id: 1, artist_name: 'Varda Veil', folder: 'varda-veil/' },
        { artist_id: 2, artist_name: 'Soluna', folder: 'soluna' },
        { artist_id: 3, artist_name: 'Watusi', folder: 'watusi' },
        { artist_id: 4, artist_name: 'litterthanlit', folder: 'litterthanlit' }
    ]);
    const [selectedArtistId, setSelectedArtistId] = useState(selectedArtist);
    const [artworks, setArtworks] = useState([]);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [loading, setLoading] = useState(true);
    const [imagesLoaded, setImagesLoaded] = useState(0);
    const [totalImages, setTotalImages] = useState(0);

    const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (selectedArtistId) {
            const selectedArtist = artists.find(artist => artist.artist_id === selectedArtistId);
            if (selectedArtist) {
                let artworkFiles = [];
                if (selectedArtist.artist_name === 'Varda Veil') {
                    artworkFiles = Array.from({ length: 16 }, (_, i) => ({
                        file: `${i + 1}.jpg`,
                        price: (i === 2 || i === 5 || i === 15) ? 'SOLD' : 'Not Listed',
                        buttonText: (i === 5) ? 'Buy on Sentx' : null,
                        buttonUrl: (i === 5) ? 'https://sentx.io/nft-marketplace/immuta-collection-vol-1' : null,
                        soldStatus: (i === 2 || i === 15) ? 'SOLD' : null
                    }));
                } else if (selectedArtist.artist_name === 'Soluna') {
                    artworkFiles = Array.from({ length: 2 }, (_, i) => ({
                        file: `${i + 1}.png`,
                        price: '$100',
                        buttonText: 'Buy on Sentx',
                        buttonUrl: 'https://sentx.io/nft-marketplace/immuta-collection-vol-1'
                    }));
                } else if (selectedArtist.artist_name === 'Watusi') {
                    artworkFiles = [{
                        file: '1.mp4',
                        price: 'Not Listed'
                    }];
                } else if (selectedArtist.artist_name === 'litterthanlit') {
                    artworkFiles = Array.from({ length: 2 }, (_, i) => ({
                        file: `${i + 1}.png`,
                        price: 'Not Listed',
                        buttonText: 'Buy on Sentx',
                        buttonUrl: 'https://sentx.io/nft-marketplace/immuta-collection-vol-1'
                    }));
                }

                const loadedArtworks = artworkFiles.map((artwork, index) => ({
                    artwork_id: index + 1,
                    image_file: `/artworks/${selectedArtist.folder}/${artwork.file}`,
                    price: artwork.price,
                    buttonText: artwork.buttonText,
                    buttonUrl: artwork.buttonUrl
                }));
                setArtworks(loadedArtworks);
            }
        }
    }, [selectedArtistId]);

    useEffect(() => {
        if (artworks.length > 0) {
            setTotalImages(artworks.length);
        }
    }, [artworks]);

    const handleImageLoad = () => {
        setImagesLoaded(prev => {
            const newCount = prev + 1;
            if (newCount === totalImages) {
                setLoading(false);
            }
            return newCount;
        });
    };

    const toggleDropdown = () => {
        setDropdownVisible(!dropdownVisible);
    };

    return (
        <div className="gallery-container">
            <h1 className="gallery__title">The Immuta Gallery</h1>
            {isMobile ? (
                <div className="mobile-artist-selector">
                    <button onClick={toggleDropdown} className="dropdown-button">
                        Select Artist <span className="dropdown-arrow">▼</span>
                    </button>
                    {dropdownVisible && (
                        <div className="dropdown-menu">
                            {artists.map(artist => (
                                <div
                                    key={artist.artist_id}
                                    className='artist-name'
                                    onClick={() => {
                                        setSelectedArtistId(artist.artist_id);
                                        setDropdownVisible(false);
                                    }}
                                >
                                    {artist.artist_name}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            ) : (
                <div className="artist-list">
                    {artists.map(artist => (
                        <div
                            key={artist.artist_id}
                            className='artist-name'
                            onClick={() => setSelectedArtistId(artist.artist_id)}
                        >
                            {artist.artist_name}
                        </div>
                    ))}
                </div>
            )}
            <div className="artwork-grid">
                {!selectedArtistId && <div className="default-text">Select an artist using the menu to view their works.</div>}
                {artworks.map(artwork => (
                    <div key={artwork.artwork_id} className="artwork-item">
                        {artwork.image_file.endsWith('.mp4') ? (
                            <video src={artwork.image_file} loop autoPlay muted className="artwork-video"></video>
                        ) : (
                            <img src={artwork.image_file} alt={`Artwork ${artwork.artwork_id}`} onLoad={handleImageLoad} style={{ opacity: loading ? 0 : 1 }} />
                        )}
                        {artwork.soldStatus ? (
                            <p className="sold-status">{artwork.soldStatus}</p>
                        ) : artwork.buttonText ? (
                            <a href={artwork.buttonUrl} target="_blank" rel="noopener noreferrer">
                                <button className="buy-button">{artwork.buttonText}</button>
                            </a>
                        ) : (
                            <p>{artwork.price}</p>
                        )}
                    </div>
                ))}
            </div>
            {loading && <div className="loading-screen">Loading...</div>}
        </div>
    );
};

export default Gallery;
