import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import './MinimalHome.css';
import p5 from 'p5';

const MinimalHome = () => {
  const [isLoading, setIsLoading] = useState(true);
  const canvasRef = useRef(null);
  const p5Instance = useRef(null);

  useEffect(() => {
    if (isLoading && canvasRef.current) {
      p5Instance.current = new p5((p) => {
        const gridX = 6;
        const gridY = 6;

        p.setup = () => {
          const canvas = p.createCanvas(300, 300);
          canvas.parent(canvasRef.current);
          p.colorMode(p.HSB, 360, 100, 100);
          p.noStroke();
        };

        p.draw = () => {
          p.background('#bdbdbd');
          let squareWidth = p.width / gridX;
          let squareHeight = p.height / gridY;

          let offsetY = p.height / 2 - (squareHeight * gridY) / 2;
          for (let x = 0; x < gridX; x++) {
            for (let y = 0; y < gridY; y++) {
              drawSquare(p, x, y, squareWidth, squareHeight, offsetY);
            }
          }
        };

        const drawSquare = (p, x, y, squareWidth, squareHeight, offsetY = 0) => {
          for (let i = 0; i < squareWidth; i++) {
            let inter = p.map(i, 0, squareWidth, 0, 1);
            let timeFactor = (p.sin(p.frameCount * 0.01 + x * 0.1 + y * 0.1) + 1) / .01;
            let colorPos = (inter + timeFactor) % 1;

            // Create a more vibrant gradient using HSB color mode
            let startColor = p.color(200, 90, 45);  // Deep blue with higher saturation
            let endColor = p.color(190, 85, 85);    // Brighter aqua blue

            let interColor = p.lerpColor(startColor, endColor, colorPos);
            p.stroke(interColor);
            p.line(x * squareWidth + i, y * squareHeight + offsetY,
              x * squareWidth + i, (y + 1) * squareHeight + offsetY);
          }
          p.noStroke();
        };
      });
    }

    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => {
      clearTimeout(timer);
      if (p5Instance.current) {
        p5Instance.current.remove();
      }
    };
  }, [isLoading]);

  return (
    <>
      <AnimatePresence>
        {isLoading && (
          <motion.div
            className="loading-screen"
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
            style={{ background: '#bdbdbd' }}
          >
            <div className="loading-content">
              <h1 className="loading-title">Immuta.art</h1>
              <div
                className="loading-canvas-container"
                ref={canvasRef}
              ></div>
              <div
                className="loading-overlay"
              ></div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      <motion.div
        className="minimal-home"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <motion.header
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8, delay: 0.2 }}
        >
          <h1>Immuta</h1>
        </motion.header>

        <main>
          <motion.section
            className="content-area"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 1.2 }}
          >
            <motion.div
              className="info-section"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5, delay: 1.6 }}
            >
              <article className="blog-post">
                <div className="blog-post-header">
                  <div className="post-title">Artist Spotlight</div>
                  <h2>litterthanlit</h2>
                </div>
                <div className="blog-post-content">
                  <img
                    src="/static/media/1.jpg"
                    alt="Additional Artwork"
                    className="blog-post-image"
                  />
                  <p className="artist-quote">"Colors are a universal language, a lens we see the world through...a window"</p>
                  <p>I use them in my pieces to speak whats on my mind how I'm perceiving the world at that time. Play is the other big element. Through play I'm able to get in a state where I flow.</p>
                  <img
                    src="/static/media/2.jpg"
                    alt="Additional Artwork"
                    className="blog-post-image"
                  />
                  <a
                    href="https://sentx.io/nft-marketplace/immuta-collection-vol-1"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="collection-button"
                  >
                    View Collection
                  </a>
                </div>
              </article>
            </motion.div>

            <motion.div
              className="large-launch-section"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5, delay: 1.6 }}
            >
              <motion.div
                className="large-launch-button"
                aria-disabled="true"
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
              >
                Launch Galleries
              </motion.div>
            </motion.div>
          </motion.section>
        </main>

        <motion.footer
          className="social-links"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 2 }}
        >
          <a href="https://twitter.com/immuta_art" target="_blank" rel="noopener noreferrer">
            𝕏
          </a>
          {/* <a href="mailto:contact@immuta.art">
            Email
          </a> */}
        </motion.footer>

        <motion.div
          className="copyright"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 2 }}
        >
          <br></br>© 2024 by BlankPaper
        </motion.div>
      </motion.div>
    </>
  );
};

export default MinimalHome; 