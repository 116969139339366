import './App.css';
import MinimalHome from './MinimalHome';
import { Routes, Route } from 'react-router-dom';

// Preserving old imports for legacy route
import './Header.css';
import './Hero.css';
import './Arrow.css';
import './Gallery.css';
import './BlankHeadsMintPage.css';
import Footer from './Footer';
import Header from './Header';
import Hero from './Hero';
import Featured from './Featured';
import ArrowDown from './ArrowDown';
import Gallery from './Gallery';
import BlankHeadsMintPage from './BlankHeadsMintPage';
import AboutPopup from './AboutPopup';
import { useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';

// Legacy App Component
const LegacyApp = () => {
  const [content, setContent] = useState('featured');
  const [isAboutOpen, setIsAboutOpen] = useState(false);
  const [selectedArtist, setSelectedArtist] = useState(null);

  const switchContent = (newContent, artistId = null) => {
    if (newContent === 'about') {
      setIsAboutOpen(true);
    } else {
      setContent(newContent);
      setSelectedArtist(artistId);
      setIsAboutOpen(false);
    }
  };

  return (
    <div className="App">
      <Header switchContent={switchContent} />
      <AnimatePresence>
        {content !== 'gallery' && !isAboutOpen && (
          <motion.div
            key="hero-featured"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
          >
            <Hero switchContent={switchContent} />
            <Featured switchContent={switchContent} />
          </motion.div>
        )}
        {content === 'gallery' && !isAboutOpen && (
          <motion.div
            key="gallery"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
          >
            <Gallery selectedArtist={selectedArtist} />
          </motion.div>
        )}
        {isAboutOpen && (
          <motion.div
            key="about"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
          >
            <AboutPopup onClose={() => setIsAboutOpen(false)} />
          </motion.div>
        )}
      </AnimatePresence>
      <Footer />
    </div>
  );
};

// New App Component
function App() {
  return (
    <Routes>
      <Route path="/" element={<MinimalHome />} />
      <Route path="/legacy/*" element={<LegacyApp />} />
    </Routes>
  );
}

export default App;
